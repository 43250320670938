import React from "react"
import styled from "styled-components"

import ArrowRight from "../assets/svg/arrow-right.svg"
import { heroButtonFontMixin } from "../assets/styles/fontStyle"

const Circle = styled.div`
  width: 36px;
  height: 36px;
  border: 1.4px solid ${({ theme }) => theme.color.main};
  background-color: ${({ theme }) => theme.color.mainBackground};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 48px;
    height: 48px;
    border: 1.8px solid ${({ theme }) => theme.color.main};
  }
`
const Icon = styled(ArrowRight)`
  width: 12px;
  height: 12px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 16px;
    height: 16px;
  }
`
const Text = styled.div`
  ${heroButtonFontMixin}
  text-transform: uppercase;
  margin-left: 8px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-left: 12px;
  }
`

const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  :hover {
    ${Circle} {
      border-color: ${({ theme }) => theme.color.hover};
      color: ${({ theme }) => theme.color.hover};
    }
    ${Icon} {
      color: ${({ theme }) => theme.color.hover};
    }
    ${Text} {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.color.hover};
      color: ${({ theme }) => theme.color.hover};
    }
  }
`

type Props = {
  href: string
  text: string
  target?: string
}

const LinkButton = ({ href, text, target, ...restProps }: Props) => (
  <Link href={href} target={target} {...restProps}>
    <Circle>
      <Icon />
    </Circle>
    <Text>{text}</Text>
  </Link>
)

export default LinkButton

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import {
  paddingMobileMixin,
  paddingTabletMixin,
  paddingDesktopMixin,
} from "../assets/styles/paddingStyle"
import { h3Mixin, featuredProjectsFontMixin } from "../assets/styles/fontStyle"
import FeaturedProjectItem from "./featured-project-item"
import LinkButton from "./linkButton"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${paddingMobileMixin}
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${paddingDesktopMixin}
  }
`

const Title = styled.h2`
  ${featuredProjectsFontMixin}
`

const ProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.color.main};
  margin-top: 20px;
  padding-top: 40px;
  > :not(:last-child) {
    margin-bottom: 40px;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    padding-top: 200px;
    > :not(:last-child) {
      margin-bottom: 200px;
    }
  }
`

const MoreText = styled.div`
  ${h3Mixin}
  margin-top: 80px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 179px;
  }
`
const LinkButtonStyled = styled(LinkButton)`
  align-self: flex-start;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 20px;
  }
`

type Props = {}
const FeaturedProjectsSection = (props: Props) => {
  const data = useStaticQuery(graphql`
    {
      klanniImage: file(relativePath: { eq: "painting-klanni.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      puntaBegonaImage: file(
        relativePath: { eq: "painting-punta-begona.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      valoTechImage: file(relativePath: { eq: "painting-valo-tech.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      alooImage: file(relativePath: { eq: "painting-aloo.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      klanniHoverImage: file(relativePath: { eq: "work-klanni.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      puntaBegonaHoverImage: file(
        relativePath: { eq: "work-punta-begona.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      valoTechHoverImage: file(relativePath: { eq: "work-valo-tech.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      alooHoverImage: file(relativePath: { eq: "work-aloo.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Title>FEATURED PROJECTS</Title>
      <ProjectsWrapper>
        <FeaturedProjectItem
          imageTitle="Klanni Painting"
          imageAlt="Abstract painting with mix of red, white and turquose colors"
          image={data.klanniImage.childImageSharp.gatsbyImageData}
          imageHoverTitle="Klanni Design Product"
          imageHoverAlt="Picture of 2 screens of Klanni app in iPhone"
          imageHover={data.klanniHoverImage.childImageSharp.gatsbyImageData}
          url="https://www.behance.net/gallery/94428407/Klanni-Flatmate-Matching-App"
          name="Klanni"
          year="(2020)"
          description="Flatmate matching app seeking to create and promote better shared living."
          reversed
        />
        <FeaturedProjectItem
          imageTitle="Punta Begoña Painting"
          imageAlt="Abstract painting with mix of dark red, black and white"
          image={data.puntaBegonaImage.childImageSharp.gatsbyImageData}
          imageHoverTitle="Punta Begoña Design Product"
          imageHoverAlt="Picture of the website shown on an Apple Desktop"
          imageHover={
            data.puntaBegonaHoverImage.childImageSharp.gatsbyImageData
          }
          url="https://www.behance.net/gallery/82333141/Punta-Begona-Branding"
          name="Punta Begoña"
          year="(2019)"
          description="Updated visual system and web design for luxury real-estate development in Mexico."
        />
        <FeaturedProjectItem
          imageTitle="Valo Tech Painting"
          imageAlt="Abstract painting with mix of blue and violet"
          image={data.valoTechImage.childImageSharp.gatsbyImageData}
          imageHoverTitle="Valo Tech Design Product"
          imageHoverAlt="Picture of Valo App shown on Iphone and tablet"
          imageHover={data.valoTechHoverImage.childImageSharp.gatsbyImageData}
          url="https://www.behance.net/gallery/94641741/Valo-Tech-Brand-Identity"
          name="Valo Tech"
          year="(2019)"
          description="Building a corporate identity for a digital startup aiming to create better home security services."
          reversed
        />
        <FeaturedProjectItem
          imageTitle="Aloo Painting"
          imageAlt="Abstract painting with mix of dark red, yellow and cream color"
          image={data.alooImage.childImageSharp.gatsbyImageData}
          imageHoverTitle="Aloo Design Product"
          imageHoverAlt="Picture of Aloo in black and white with a flower inside of it"
          imageHover={data.alooHoverImage.childImageSharp.gatsbyImageData}
          url="https://www.behance.net/gallery/83633997/Aloo-Product-Design"
          name="Aloo"
          year="(2018)"
          description="Creating healthier spending habits through visual and emotional feedback"
        />
      </ProjectsWrapper>
      <MoreText>
        WANNA <br />
        SEE MORE?
      </MoreText>
      <LinkButtonStyled
        href="https://www.behance.net/cgutierrezcorte/projects"
        text="RIGHT THIS WAY"
        target="_blank"
      />
    </Container>
  )
}

export default FeaturedProjectsSection

import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  paddingMobileMixin,
  paddingTabletMixin,
  paddingDesktopMixin,
} from "../assets/styles/paddingStyle"
import {
  contactTitleFontMixin,
  contactPillFontMixin,
  contactFollowFontMixin,
  linkMixin,
  creditsFontMixin,
} from "../assets/styles/fontStyle"

const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${paddingMobileMixin}
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${paddingDesktopMixin}
    position: relative;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    position: relative;
  }
`
const Title = styled.h2`
  ${contactTitleFontMixin}
  text-align: center;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    align-self: center;
    max-width: 50%;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-start;
    text-align: left;
  }
`

const MemojiImageWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: none;
  }
`
const MemojiImageDesktopWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    bottom: 100px;
  }
`

const MemojiImage = styled(GatsbyImage)`
  max-width: 480px;
  max-height: 480px;
  width: 100%;
`

const ContactLinksFlexWrapper = styled.div`
  flex: 1;
`

const ContactLinksWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto;
  gap: 12px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 64px;
    grid-template-columns: max-content auto;
    gap: 16px;
  }
`
const ContactLink = styled.a`
  ${contactPillFontMixin}
  border: 1.4px solid ${({ theme }) => theme.color.main};
  background-color: ${({ theme }) => theme.color.mainBackground};
  border-radius: 32px;
  padding: 5px 16px 3px;
  margin: auto;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.color.mainBackground};
    background-color: ${({ theme }) => theme.color.main};
  }

  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin: 0;
    margin-right: auto;
    border-width: 2px;
    padding: 7px 20px 4px;
  }
`

const FollowWrapper = styled.div`
  margin-top: 54px;
  margin-bottom: 20px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 64px;
    margin-bottom: 20px;
  }
`
const FollowTitle = styled.div`
  ${contactFollowFontMixin}
`
const FollowLinks = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: auto auto;
  gap: 32px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    grid-template-columns: max-content max-content max-content max-content;
    grid-template-rows: auto;
    gap: 40px;
  }
`
const FollowLink = styled.a`
  ${linkMixin}
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.hover};
    text-decoration-color: ${({ theme }) => theme.color.hover};
  }
`

const CreditsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.main};
  padding-top: 20px;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    "a"
    "b"
    "c";
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    padding-top: 24px;
    padding-bottom: 40px;
    grid-template-columns: auto min-content min-content auto;
    grid-template-rows: auto auto;
    gap: 12px 40px;
    grid-template-areas:
      ". a b ."
      ". c c .";
  }
`
const CreditsText1 = styled.div`
  ${creditsFontMixin}
  text-align: center;
  margin: auto;
  grid-area: a;
  strong {
    font-weight: 600;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    white-space: nowrap;
  }
`
const CreditsText2 = styled(CreditsText1)`
  grid-area: b;
`
const CreditsText3 = styled(CreditsText1)`
  grid-area: c;
`

const SamitoLink = styled.a`
  ${creditsFontMixin}
  font-weight: 600;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.hover};
    text-decoration-color: ${({ theme }) => theme.color.hover};
  }
`

type Props = {}
const ContactSection = (props: Props) => {
  const data = useStaticQuery(graphql`
    {
      memojiImage: file(relativePath: { eq: "memoji.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <ContentWrapper>
        <Title>LET’S DO SOMETHING GREAT!</Title>
        <MemojiImageWrapper>
          <MemojiImage
            alt=""
            image={data.memojiImage.childImageSharp.gatsbyImageData}
          />
        </MemojiImageWrapper>
        <ContactLinksFlexWrapper>
          <ContactLinksWrapper>
            <ContactLink href="mailto:cgutierrezcorte@gmail.com">
              EMAIL
            </ContactLink>
            <ContactLink
              href="https://www.linkedin.com/in/cgutierrezcorte"
              target="_blank"
            >
              LINKEDIN
            </ContactLink>
          </ContactLinksWrapper>
        </ContactLinksFlexWrapper>
        <FollowWrapper>
          <FollowTitle>Follow me 🌈</FollowTitle>
          <FollowLinks>
            <FollowLink
              href="https://www.linkedin.com/in/cgutierrezcorte"
              target="_blank"
            >
              LinkedIn ↗
            </FollowLink>
            <FollowLink href="https://dribbble.com/cgutierrez" target="_blank">
              Dribbble ↗
            </FollowLink>
            <FollowLink
              href="https://www.instagram.com/allaboutlalo/"
              target="_blank"
            >
              Instagram ↗
            </FollowLink>
            <FollowLink>Twitter ↗</FollowLink>
          </FollowLinks>
        </FollowWrapper>
        <MemojiImageDesktopWrapper>
          <MemojiImage
            alt=""
            image={data.memojiImage.childImageSharp.gatsbyImageData}
          />
        </MemojiImageDesktopWrapper>
      </ContentWrapper>
      <CreditsWrapper>
        <CreditsText1>
          ART &amp; WEB DESIGN BY <strong>CARLOS GUTIÉRREZ</strong>
        </CreditsText1>
        <CreditsText2>
          WEB DEVELOPMENT BY{" "}
          <SamitoLink
            href="https://www.linkedin.com/in/radimak/"
            target="_blank"
          >
            SAMUEL RADIMÁK ↗
          </SamitoLink>
        </CreditsText2>
        <CreditsText3>
          ALL RIGHTS RESERVED <strong>2021© CARLOS GUTIÉRREZ</strong>
        </CreditsText3>
      </CreditsWrapper>
    </Container>
  )
}

export default ContactSection

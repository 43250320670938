import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout, PageEnum } from "../components/layout"
import HeroSection from "../components/hero-section"
import FeaturedProjectsSection from "../components/featured-projects-section"
import NonProfitProjectsSection from "../components/non-profit-projects-section"
import ContactSection from "../components/contact-section"

const HeroSectionStyled = styled(HeroSection)`
  margin-top: 40px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 138px;
  }
`

const FeaturedProjectsSectionStyled = styled(FeaturedProjectsSection)`
  margin-top: 160px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 200px;
  }
`

const NonProfitProjectsSectionStyled = styled(NonProfitProjectsSection)`
  margin-top: 160px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 200px;
  }
`

const ContactSectionStyled = styled(ContactSection)`
  margin-top: 0px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 0px;
  }
`

const IndexPage = () => (
  <Layout page={PageEnum.PROJECTS}>
    <SEO />
    <HeroSectionStyled />
    <FeaturedProjectsSectionStyled />
    <NonProfitProjectsSectionStyled />
    <ContactSectionStyled />
  </Layout>
)

export default IndexPage

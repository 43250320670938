import React from "react"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import {
  contactTitleFontMixin,
  aboutFontMixin,
  creditsFontMixin,
} from "../assets/styles/fontStyle"
import LinkButton from "./linkButton"

const ImageWrapper = styled.div`
  width: 100%;
  /* aspect-ratio: 1.759; */
  padding-bottom: calc(100% / 1.759);
  position: relative;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    flex: 38;
  }
`
const MainImageWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
const HoverImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: top 1s ease, left 1s ease, right 1s ease, bottom 1s ease;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    opacity: 0;
  }
`
const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const InfoWrapper = styled(({ reversed, ...rest }) => <div {...rest} />)`
  margin-top: 10px;
  align-self: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin: 0;
    flex: 62;
    ${({ reversed }) => (reversed ? "margin-right" : "margin-left")}: 60px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: ${({ reversed }) => (reversed ? "flex-end" : "flex-start")};
  }
`

const Name = styled.div`
  ${contactTitleFontMixin}
  text-transform: uppercase;
`
const Description = styled(({ reversed, ...rest }) => <div {...rest} />)`
  ${aboutFontMixin}
  margin-top: 4px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 8px;
    text-align: ${({ reversed }) => (reversed ? "end" : "start")};
    max-width: 288px;
  }
`
const Year = styled.span`
  ${creditsFontMixin}
  margin-right: 20px;
`

const LinkButtonStyled = styled(LinkButton)`
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 20px;
  }
`

const Container = styled(({ reversed, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    flex-direction: ${({ reversed }) => (reversed ? "row-reverse" : "row")};
    justify-content: stretch;
  }

  /* &:hover {
    ${MainImageWrapper} {
      display: none;
    }
    ${HoverImageWrapper} {
      opacity: 1;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
    }
  } */
`

type Props = {
  imageTitle: string
  imageAlt: string
  image: IGatsbyImageData
  imageHoverTitle: string
  imageHoverAlt: string
  imageHover: IGatsbyImageData
  url: string
  name: string
  year: string
  description: string
  reversed?: boolean
}

const FeaturedProjectItem = ({
  imageTitle,
  imageAlt,
  image,
  imageHoverTitle,
  imageHoverAlt,
  imageHover,
  url,
  name,
  year,
  description,
  reversed,
  ...restProps
}: Props) => (
  <Container
    data-sal="slide-up"
    data-sal-delay="300"
    data-sal-duration="1000"
    data-sal-easing="ease"
    reversed={reversed}
    {...restProps}
  >
    <ImageWrapper>
      <MainImageWrapper>
        <Image title={imageTitle} alt={imageAlt} image={image} />
      </MainImageWrapper>
      <HoverImageWrapper>
        <Image title={imageHoverTitle} alt={imageHoverAlt} image={imageHover} />
      </HoverImageWrapper>
    </ImageWrapper>
    <InfoWrapper reversed={reversed}>
      <Name>{name}</Name>
      <Description reversed={reversed}>
        <Year>{year}</Year>
        {description}
      </Description>
      <LinkButtonStyled href={url} target="_blank" text="VIEW PROJECT" />
    </InfoWrapper>
  </Container>
)

export default FeaturedProjectItem

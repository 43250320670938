import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"

import {
  marginMobileMixin,
  marginTabletMixin,
  marginDesktopMixin,
} from "../assets/styles/paddingStyle"
import {
  aboutFontMixin,
  creditsFontMixin,
  h3Mixin,
} from "../assets/styles/fontStyle"

const Container = styled.section`
  position: relative;
`
const MarginWrapper = styled.div`
  ${marginMobileMixin}
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${marginTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${marginDesktopMixin}
  }
`
const ContentWrapper = styled.div`
  padding-top: 90%;
  padding-bottom: 389%;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    padding-top: 64%;
    padding-bottom: 40%;
  }
`
const Date = styled.div`
  ${creditsFontMixin}
`
const Title = styled.h2`
  ${h3Mixin}
  margin-top: 4px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 10px;
  }
`
const SUBTITLE_HEIGHT = "280px"
const TITLE_SUBTITLE_DESKTOP_HEIGHT = "344px"
const SubtitleWrapper = styled.div`
  margin-top: calc(42% + 40px);
  height: ${SUBTITLE_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 20px;
    height: inherit;
  }
`
const Subtitle = styled.p`
  ${aboutFontMixin}
  text-align: start;
  max-width: 34rem;
  strong {
    font-weight: 600;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    text-align: center;
    max-width: 43rem;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const ImageParallax = styled(Parallax).attrs({
  styleInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})``
const TITLE_HEIGHT = "90px"
const Image1Wrapper = styled(ImageWrapper)`
  margin-top: calc(90% + ${TITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 42%;
  }
`
const Image1 = styled(GatsbyImage)`
  width: 42%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 16%;
  }
`
const Image2Wrapper = styled(ImageWrapper)`
  margin-top: calc(132% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 28%;
  }
`
const Image2 = styled(GatsbyImage)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-end;
    width: 31%;
  }
`
const Image3Wrapper = styled(ImageWrapper)`
  margin-top: calc(225% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(72% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-left: 7%;
  }
`
const Image3 = styled(GatsbyImage)`
  align-self: flex-start;
  width: 35%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 14%;
  }
`
const Image4Wrapper = styled(ImageWrapper)`
  margin-top: calc(260% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(82% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-left: 14%;
  }
`
const Image4 = styled(GatsbyImage)`
  align-self: flex-end;
  width: 70%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-start;
    width: 36%;
  }
`
const Image5Wrapper = styled(ImageWrapper)`
  margin-top: calc(335% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(72% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-right: 17%;
  }
`
const Image5 = styled(GatsbyImage)`
  align-self: flex-start;
  width: 64%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-end;
    width: 21%;
  }
`
const Image6Wrapper = styled(ImageWrapper)`
  margin-top: calc(380% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(82% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-right: 6%;
  }
`
const Image6 = styled(GatsbyImage)`
  align-self: flex-end;
  width: 52%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 18%;
  }
`
const Image7Wrapper = styled(ImageWrapper)`
  margin-top: calc(450% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 26%;
    margin-left: 8%;
  }
`
const Image7 = styled(GatsbyImage)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-start;
    width: 19%;
  }
`

const EllipseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: none;
  }
`
const EllipseParallax = styled(Parallax)`
  width: 100%;
`
const EllipseImage = styled(GatsbyImage)`
  width: 100%;
`
const EllipseOrangeWrapper = styled(EllipseWrapper)``
const EllipseBlueWrapper = styled(EllipseWrapper)`
  margin-top: 90%;
`
const EllipseOrangeVerticalWrapper = styled(EllipseWrapper)`
  margin-top: 290%;
`
const SphereWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`
const SphereParallax = styled(Parallax).attrs({
  styleInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})``
const SphereOrange1Wrapper = styled(SphereWrapper)``
const SphereOrange1Image = styled(GatsbyImage)`
  align-self: flex-start;
  width: 40%;
`
const SphereBlueWrapper = styled(SphereWrapper)`
  margin-top: 14%;
  margin-right: -20%;
`
const SphereBlueImage = styled(GatsbyImage)`
  align-self: flex-end;
  width: 50%;
`
const SphereOrange2Wrapper = styled(SphereWrapper)`
  margin-top: 52%;
  margin-left: -8%;
`
const SphereOrange2Image = styled(GatsbyImage)`
  align-self: flex-start;
  width: 60%;
`

type Props = {}
const ProjectSamko = (props: Props) => {
  const data = useStaticQuery(graphql`
    {
      ellipseOrange: file(relativePath: { eq: "ellipse-orange.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ellipseBlue: file(relativePath: { eq: "ellipse-blue.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ellipseOrangeVertical: file(
        relativePath: { eq: "ellipse-orange-vertical.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      sphereOrange1: file(relativePath: { eq: "sphere-orange-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      sphereBlue: file(relativePath: { eq: "sphere-blue.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      sphereOrange2: file(relativePath: { eq: "sphere-orange-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko1Image: file(relativePath: { eq: "project-samko-1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko2Image: file(relativePath: { eq: "project-samko-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko3Image: file(relativePath: { eq: "project-samko-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko4Image: file(relativePath: { eq: "project-samko-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko5Image: file(relativePath: { eq: "project-samko-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko6Image: file(relativePath: { eq: "project-samko-6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectSamko7Image: file(relativePath: { eq: "project-samko-7.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <MarginWrapper>
        <ContentWrapper>
          <Date>2019</Date>
          <Title>SAMKO</Title>

          <SubtitleWrapper>
            <Subtitle
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              A forest fire detection system in collaboration with{" "}
              <strong>GEIQ</strong> (General Electic) to aid in the
              fire-fighting efforts for Sierra Gorda de Querétaro during fire
              season.
              <br />
              <br />
              <strong>
                Designed through generative CAD and biomimicry.
              </strong>{" "}
              Inspired by a cactus skeleton to achieve a durable yet lightweight
              drone body structure.
            </Subtitle>
          </SubtitleWrapper>
          <Image1Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image1
                alt="Picture of a drone held in a hand"
                image={data.projectSamko1Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image1Wrapper>
          <Image2Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image2
                alt="Picture of multiple flying drones in wild nature"
                image={data.projectSamko2Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image2Wrapper>
          <Image3Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image3
                alt="Picture of rock mountain"
                image={data.projectSamko3Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image3Wrapper>

          <Image4Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image4
                alt="Picture of drone body detail"
                image={data.projectSamko4Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image4Wrapper>
          <Image5Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image5
                alt="Picture of drone detail in computer model"
                image={data.projectSamko5Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image5Wrapper>
          <Image6Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image6
                alt="Picture of drone detail in computer model with only body"
                image={data.projectSamko6Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image6Wrapper>
          <Image7Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image7
                alt="Picture of a drone flying forward"
                image={data.projectSamko7Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image7Wrapper>
        </ContentWrapper>
      </MarginWrapper>
      <EllipseOrangeWrapper>
        <EllipseParallax y={[0, 0]}>
          <EllipseImage
            alt=""
            image={data.ellipseOrange.childImageSharp.gatsbyImageData}
          />
        </EllipseParallax>
      </EllipseOrangeWrapper>
      <EllipseBlueWrapper>
        <EllipseParallax y={[0, 0]}>
          <EllipseImage
            alt=""
            image={data.ellipseBlue.childImageSharp.gatsbyImageData}
          />
        </EllipseParallax>
      </EllipseBlueWrapper>
      <EllipseOrangeVerticalWrapper>
        <EllipseParallax y={[0, 0]}>
          <EllipseImage
            alt=""
            image={data.ellipseOrangeVertical.childImageSharp.gatsbyImageData}
          />
        </EllipseParallax>
      </EllipseOrangeVerticalWrapper>
      <SphereOrange1Wrapper>
        <SphereParallax y={[-30, 70]}>
          <SphereOrange1Image
            alt=""
            image={data.sphereOrange1.childImageSharp.gatsbyImageData}
          />
        </SphereParallax>
      </SphereOrange1Wrapper>
      <SphereBlueWrapper>
        <SphereParallax y={[-20, 60]} x={[0, -40]}>
          <SphereBlueImage
            alt=""
            image={data.sphereBlue.childImageSharp.gatsbyImageData}
          />
        </SphereParallax>
      </SphereBlueWrapper>
      <SphereOrange2Wrapper>
        <SphereParallax y={[0, 70]} x={[0, 60]}>
          <SphereOrange2Image
            alt=""
            image={data.sphereOrange2.childImageSharp.gatsbyImageData}
          />
        </SphereParallax>
      </SphereOrange2Wrapper>
    </Container>
  )
}

export default ProjectSamko

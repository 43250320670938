import React from "react"
import styled from "styled-components"

import {
  paddingMobileMixin,
  paddingTabletMixin,
  paddingDesktopMixin,
} from "../assets/styles/paddingStyle"
import { h3Mixin, aboutFontMixin } from "../assets/styles/fontStyle"
import ProjectSamko from "./project-samko"
import ProjectLabor from "./project-labor"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    position: relative;
  }
`

const IntroWrapper = styled.div`
  ${paddingMobileMixin}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${paddingDesktopMixin}
  }
`

const Title = styled.h2`
  ${h3Mixin}
  @media (min-width: ${({ theme }) => theme.screen.mobileLarge}) {
    max-width: 23rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    max-width: 36rem;
  }
`
const Subtitle = styled.p`
  ${aboutFontMixin}
  margin-top: 20px;
  strong {
    font-weight: 600;
  }
  @media (min-width: ${({ theme }) => theme.screen.mobileLarge}) {
    max-width: 24rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    max-width: 36rem;
  }
`

const ProjectSamkoStyled = styled(ProjectSamko)`
  margin-top: 0px;
`
const ProjectLaborStyled = styled(ProjectLabor)`
  margin-top: 0px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 200px;
  }
`

type Props = {}
const NonProfitProjectsSection = (props: Props) => {
  return (
    <Container {...props}>
      <IntroWrapper>
        <Title>PROJECTS CLOSE TO MY HEART</Title>
        <Subtitle
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          Non-profit initiatives that focused on{" "}
          <strong>empowering and giving back</strong> to the local community.
        </Subtitle>
      </IntroWrapper>
      <ProjectSamkoStyled />
      <ProjectLaborStyled />
    </Container>
  )
}

export default NonProfitProjectsSection

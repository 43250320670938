import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"

import {
  marginMobileMixin,
  marginTabletMixin,
  marginDesktopMixin,
} from "../assets/styles/paddingStyle"
import {
  creditsFontMixin,
  h3Mixin,
  aboutFontMixin,
} from "../assets/styles/fontStyle"

const Container = styled.section`
  position: relative;
`
const MarginWrapper = styled.div`
  ${marginMobileMixin}
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${marginTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${marginDesktopMixin}
  }
`
const ContentWrapper = styled.div`
  padding-top: 100%;
  padding-bottom: 400%;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    padding-top: 30%;
    padding-bottom: 100%;
  }
`
const Date = styled.div`
  ${creditsFontMixin}
`
const Title = styled.h2`
  ${h3Mixin}
  margin-top: 4px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 10px;
  }
`
const SUBTITLE_HEIGHT = "280px"
const TITLE_SUBTITLE_DESKTOP_HEIGHT = "344px"
const SubtitleWrapper = styled.div`
  margin-top: calc(42% + 40px);
  height: ${SUBTITLE_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 20px;
    height: inherit;
  }
`
const Subtitle = styled.p`
  ${aboutFontMixin}
  text-align: start;
  max-width: 34rem;
  strong {
    font-weight: 600;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    text-align: center;
  }
`
const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const ImageParallax = styled(Parallax).attrs({
  styleInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})``
const TITLE_HEIGHT = "90px"
const Image1Wrapper = styled(ImageWrapper)`
  margin-top: calc(100% + ${TITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(40% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    margin-top: calc(34%);
  }
`
const Image1 = styled(GatsbyImage)`
  width: 42%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-end;
    width: 22%;
  }
`
const Image2Wrapper = styled(ImageWrapper)`
  margin-top: calc(143% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(70% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-right: 12%;
  }
`
const Image2 = styled(GatsbyImage)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-end;
    width: 28%;
  }
`
const Image3Wrapper = styled(ImageWrapper)`
  margin-top: calc(240% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(50% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-left: 7%;
  }
`
const Image3 = styled(GatsbyImage)`
  align-self: flex-start;
  width: 47%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 21%;
  }
`
const Image4Wrapper = styled(ImageWrapper)`
  margin-top: calc(277% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(80% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
    margin-left: 14%;
  }
`
const Image4 = styled(GatsbyImage)`
  align-self: flex-end;
  width: 70%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-self: flex-start;
    width: 34%;
  }
`
const Image5Wrapper = styled(ImageWrapper)`
  margin-top: calc(343% + ${TITLE_HEIGHT} + ${SUBTITLE_HEIGHT});
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: calc(40% + ${TITLE_SUBTITLE_DESKTOP_HEIGHT});
  }
`
const Image5 = styled(GatsbyImage)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 18%;
  }
`

const EllipseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: none;
  }
`
const EllipseParallax = styled(Parallax)`
  width: 100%;
`
const EllipseImage = styled(GatsbyImage)`
  width: 100%;
`
const EllipsePink1Wrapper = styled(EllipseWrapper)``
const EllipseGoldenWrapper = styled(EllipseWrapper)`
  margin-top: 230%;
`
const EllipsePink2Wrapper = styled(EllipseWrapper)`
  margin-top: 290%;
`

const SphereWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`
const SphereParallax = styled(Parallax).attrs({
  styleInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})``
const SpherePink1Wrapper = styled(SphereWrapper)`
  margin-right: 15%;
`
const SpherePink1Image = styled(GatsbyImage)`
  width: 70%;
`
const SphereGoldenWrapper = styled(SphereWrapper)`
  margin-top: 52%;
  margin-left: -8%;
`
const SphereGoldenImage = styled(GatsbyImage)`
  align-self: flex-start;
  width: 40%;
`
const SpherePink2Wrapper = styled(SphereWrapper)`
  margin-top: 80%;
  margin-right: 6%;
`
const SpherePink2Image = styled(GatsbyImage)`
  align-self: flex-end;
  width: 70%;
`

type Props = {}
const ProjectLabor = (props: Props) => {
  const data = useStaticQuery(graphql`
    {
      ellipsePink1: file(relativePath: { eq: "ellipse-pink-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ellipsePink2: file(relativePath: { eq: "ellipse-pink-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ellipseGolden: file(relativePath: { eq: "ellipse-golden.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      spherePink1: file(relativePath: { eq: "sphere-pink-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      spherePink2: file(relativePath: { eq: "sphere-pink-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      sphereGolden: file(relativePath: { eq: "sphere-golden.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectLabor1Image: file(relativePath: { eq: "project-labor-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectLabor2Image: file(relativePath: { eq: "project-labor-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectLabor3Image: file(relativePath: { eq: "project-labor-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectLabor4Image: file(relativePath: { eq: "project-labor-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      projectLabor5Image: file(relativePath: { eq: "project-labor-5.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <MarginWrapper>
        <ContentWrapper>
          <Date>2016</Date>
          <Title>LABOR</Title>
          <SubtitleWrapper>
            <Subtitle
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              An immersive educational experience co-designed with Otomi
              communities in Mexico.
              <br />
              <br />
              Through this social startup we sought to{" "}
              <strong>
                enable better job opportunities for crafstmen and spread
                awareness
              </strong>{" "}
              of the importance of the Otomi culture and heritage across the
              country.
            </Subtitle>
          </SubtitleWrapper>

          <Image1Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image1
                alt="Picture of people walking in a field"
                image={data.projectLabor1Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image1Wrapper>
          <Image2Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image2
                alt="Picture of a craftsman making a chair"
                image={data.projectLabor2Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image2Wrapper>
          <Image3Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image3
                alt="Picture of a chair made by a craftsman"
                image={data.projectLabor3Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image3Wrapper>
          <Image4Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image4
                alt="Picture of how a chair is made by a volunteer"
                image={data.projectLabor4Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image4Wrapper>
          <Image5Wrapper>
            <ImageParallax y={[0, 0]}>
              <Image5
                alt="Picture of typical mexican dolls"
                image={data.projectLabor5Image.childImageSharp.gatsbyImageData}
              />
            </ImageParallax>
          </Image5Wrapper>
        </ContentWrapper>
      </MarginWrapper>

      <EllipsePink1Wrapper>
        <EllipseParallax y={[0, 0]}>
          <EllipseImage
            alt=""
            image={data.ellipsePink1.childImageSharp.gatsbyImageData}
          />
        </EllipseParallax>
      </EllipsePink1Wrapper>
      <EllipseGoldenWrapper>
        <EllipseParallax y={[0, 0]}>
          <EllipseImage
            alt=""
            image={data.ellipseGolden.childImageSharp.gatsbyImageData}
          />
        </EllipseParallax>
      </EllipseGoldenWrapper>
      <EllipsePink2Wrapper>
        <EllipseParallax y={[0, 0]}>
          <EllipseImage
            alt=""
            image={data.ellipsePink2.childImageSharp.gatsbyImageData}
          />
        </EllipseParallax>
      </EllipsePink2Wrapper>

      <SpherePink1Wrapper>
        <SphereParallax y={[-40, 0]} x={[-40, 100]}>
          <SpherePink1Image
            alt=""
            image={data.spherePink1.childImageSharp.gatsbyImageData}
          />
        </SphereParallax>
      </SpherePink1Wrapper>
      <SphereGoldenWrapper>
        <SphereParallax x={[-10, 20]} y={[-70, 40]}>
          <SphereGoldenImage
            alt=""
            image={data.sphereGolden.childImageSharp.gatsbyImageData}
          />
        </SphereParallax>
      </SphereGoldenWrapper>
      <SpherePink2Wrapper>
        <SphereParallax x={[50, -20]} y={[-50, 0]}>
          <SpherePink2Image
            alt=""
            image={data.spherePink2.childImageSharp.gatsbyImageData}
          />
        </SphereParallax>
      </SpherePink2Wrapper>
    </Container>
  )
}

export default ProjectLabor

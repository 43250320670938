import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"
import { useMediaQuery } from "react-responsive"
import LinkButton from "./linkButton"
import {
  paddingMobileMixin,
  paddingTabletMixin,
  paddingDesktopMixin,
} from "../assets/styles/paddingStyle"
import {
  h1Mixin,
  aboutFontMixin,
  heroTextFontMixin,
} from "../assets/styles/fontStyle"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${paddingMobileMixin}
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${paddingDesktopMixin}
    position: relative;
  }
`

const HeadLine = styled.h1`
  ${h1Mixin}
  @media (min-width: ${({ theme }) => theme.screen.mobileLarge}) {
    align-self: center;
  }
`
const HeadLine1 = styled.div``
const HeadLine2 = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.mobileLarge}) {
    margin-left: 2.95rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    margin-left: 4.1rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-left: 8.1rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    margin-left: 9.2rem;
  }
`
const HeadLine3 = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.mobileLarge}) {
    margin-left: 5.9rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    margin-left: 8.2rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-left: 16.2rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    margin-left: 18.4rem;
  }
`

const ProfileImageWrapper = styled.div`
  margin-top: 40px;
  position: relative;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
  }
`
const ProfileImage = styled(GatsbyImage)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 30%;
  }
`
const ProfileEllipseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    align-items: flex-end;
  }
`
const ProfileEllipseParallax = styled(Parallax)`
  width: 42%;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    width: 12%;
  }
`
const ProfileEllipseImage = styled(GatsbyImage)`
  width: 100%;
`

const Introduction = styled.p`
  ${aboutFontMixin}
  margin-top: 40px;
  text-align: left;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 180px;
  }
`

const Title = styled.div`
  ${heroTextFontMixin}
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 16px;
  }
`
const Title1 = styled.div`
  text-align: left;
`
const Title2 = styled.div`
  text-align: left;
  font-weight: 600;
  margin-left: 2.7rem;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-left: 5.2rem;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`
const Title3 = styled.div`
  text-align: left;
`
const LinkButtonStyled = styled(LinkButton)`
  align-self: flex-start;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 40px;
  }
`

type Props = {}
const HeroSection = (props: Props) => {
  const theme = useContext(ThemeContext)
  const isDesktopOrBigger = useMediaQuery({
    query: `(min-width: ${theme.screen.desktop})`,
  })

  const data = useStaticQuery(graphql`
    {
      profileImage: file(relativePath: { eq: "profile-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ellipseYellow: file(relativePath: { eq: "ellipse-yellow.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <Container {...props}>
      <HeadLine>
        <HeadLine1
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          FULL-STACK
        </HeadLine1>
        <HeadLine2
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          PRODUCT
        </HeadLine2>
        <HeadLine3
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          DESIGNER
        </HeadLine3>
      </HeadLine>
      <ProfileImageWrapper>
        <ProfileImage
          title="Carlos Gutierrez"
          alt="Casual picture of Carlos with nature in the background"
          image={data.profileImage.childImageSharp.gatsbyImageData}
        />
        <ProfileEllipseWrapper>
          <ProfileEllipseParallax
            x={isDesktopOrBigger ? [-100, -200] : [60, -30]}
            y={isDesktopOrBigger ? [-60, 180] : [-200, 120]}
          >
            <ProfileEllipseImage
              alt=""
              image={data.ellipseYellow.childImageSharp.gatsbyImageData}
            />
          </ProfileEllipseParallax>
        </ProfileEllipseWrapper>
      </ProfileImageWrapper>
      <Introduction>Hey, Carlos here ✌</Introduction>
      <Title>
        <Title1>
          I’m a Berlin based product designer. <br />
          Currently
        </Title1>
        <Title2
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          Head of product design at Plantix,
        </Title2>
        <Title3>
          transforming the way farmers
          <br />
          around the world 🌍 manage and
          <br />
          grow their crops.
        </Title3>
      </Title>
      <LinkButtonStyled href="/about" text="Learn More" />
    </Container>
  )
}

export default HeroSection
